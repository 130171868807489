export const APP_NAME = 'Archives'
export const APP_TAGLINE = 'An app to track anything.'
export const APP_DESCRIPTION = `
Log cups of coffee, collect ideas, or keep a journal. Archives is an iOS app to save and track what matters to you in an easy and customizable way.
`
// Beta Signup
export const INTERESTED_IN_TRYING = `Interested in trying Archives before it's released?`
export const THANK_YOU_FOR_SIGNUP = `Thank you for signing up to test Archives!`
export const SIGN_UP_BETA = `Sign up for the beta.`
export const YOUR_EMAIL_ADDRESS = `Your Email Address`

// Built For Speed: Feature Display
export const BUILT_FOR_SPEED_TITLE = `Built for Speed`
export const BUILT_FOR_SPEED_DESC = `
Dont waste time jumping through screens. Start creating a new record with a single tap after opening the app.
`

// Explore: Feature Display
export const EXPLORE_TITLE = `Explore`
export const EXPLORE_DESC = `
Not sure what you want to track? Find something new with the explore tab.
`

// Analyze: Feature Display
export const ANALYZE_TITLE = `Analyze`
export const ANALYZE_DESC = `
Understand the data you collect with features such as sort, filter, search, and stats.
`

// Customize: Feature Display
export const CUSTOMIZE_TITLE = `Customize`
export const CUSTOMIZE_DESC = `Archives is built to be flexible. Choose your own colors, icons, fields and more to make the app your own.`