import React from "react"

import { SECONDARY_TEXT } from "../colors"
import {
  APP_DESCRIPTION,
  APP_NAME,
  APP_TAGLINE,
  BUILT_FOR_SPEED_TITLE,
  BUILT_FOR_SPEED_DESC,
  EXPLORE_TITLE,
  EXPLORE_DESC,
  ANALYZE_TITLE,
  ANALYZE_DESC,
  CUSTOMIZE_TITLE,
  CUSTOMIZE_DESC,
} from "../strings"
import Layout from "../components/layout"
import IMG from "gatsby-image"
import { graphql } from "gatsby"
import FeatureDisplay from "../components/featureDislay/featureDisplay"
import SEO from "../components/seo"

export default ({ data }) => {
  return (
    <Layout>
      <SEO title={"Welcome to Archives"} />
      <h1>{APP_NAME}</h1>
      <h2 style={{ marginBottom: "30px" }}>{APP_TAGLINE}</h2>
      <h5 style={{ marginBottom: "75px", color: SECONDARY_TEXT }}>
        {APP_DESCRIPTION}
      </h5>
      <div style={{display: "flex", alignItems: 'center', justifyContent: 'center'}}>
        <button style={{backgroundColor: "transparent", outline: "none", border: "none"}}onClick={() => {
          window.location.href = "https://apps.apple.com/us/app/archives-track-anything/id1560455804"
        }}>
          <IMG fixed={data.appstoreDownload.childImageSharp.fixed} />
        </button>
      </div>
      <div>
        <IMG
          style={{ marginTop: "75px" }}
          fluid={data.ipadAndIPhone.childImageSharp.fluid}
        />
      </div>
      <FeatureDisplay
        title={EXPLORE_TITLE}
        description={EXPLORE_DESC}
        icon={data.exploreIcon.childImageSharp.fluid}
        screen={data.exploreScreen.childImageSharp.fluid}
        iconOnLeft={true}
      />
      <FeatureDisplay
        title={CUSTOMIZE_TITLE}
        description={CUSTOMIZE_DESC}
        icon={data.customizeIcon.childImageSharp.fluid}
        screen={data.customizeScreen.childImageSharp.fluid}
        iconOnLeft={false}
      />
      <FeatureDisplay
        title={BUILT_FOR_SPEED_TITLE}
        description={BUILT_FOR_SPEED_DESC}
        icon={data.builtForSpeedIcon.childImageSharp.fluid}
        screen={data.builtForSpeedScreen.childImageSharp.fluid}
        iconOnLeft={true}
      />
      <FeatureDisplay
        title={ANALYZE_TITLE}
        description={ANALYZE_DESC}
        icon={data.analyzeIcon.childImageSharp.fluid}
        screen={data.analyzeScreen.childImageSharp.fluid}
        iconOnLeft={false}
      />
    </Layout>
  )
}

export const query = graphql`
  {
    ipadAndIPhone: file(relativePath: { eq: "ipadAndPhone.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
    builtForSpeedIcon: file(relativePath: { eq: "builtForSpeedIcon.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
    builtForSpeedScreen: file(relativePath: { eq: "builtForSpeedScreen.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
    exploreIcon: file(relativePath: { eq: "exploreIcon.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
    exploreScreen: file(relativePath: { eq: "exploreScreen.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
    analyzeIcon: file(relativePath: { eq: "analyzeIcon.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
    analyzeScreen: file(relativePath: { eq: "analyzeScreen.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
    customizeIcon: file(relativePath: { eq: "customizeIcon.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
    customizeScreen: file(relativePath: { eq: "customizeScreen.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
    appstoreDownload: file(relativePath: { eq: "appStoreDownload.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
