import React from "react";
import PropTypes from "prop-types"
import FeatureDisplayContent from "./featureDisplayContent"
import IMG from "gatsby-image";
import styles from "./featureDisplay.module.css"

const FeatureDisplay = (props) => {
    const mainContainerClass = props.iconOnLeft ? styles.mainContainerLeftToRight : styles.mainContainerRightToLeft
    return (
        <div className={mainContainerClass} data-sal='zoom-in' data-sal-duration='300'>
            <div className={styles.featureContentContainer}>
                <FeatureDisplayContent className={styles.featureContent} {...props} />
            </div>
            <div className={styles.screenContainer}>
                 <IMG fluid={props.screen} className={styles.screenImage} />
            </div>
        </div>
    )
}

FeatureDisplay.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    screen: PropTypes.object.isRequired,
    iconOnLeft: PropTypes.bool.isRequired,
}

export default FeatureDisplay;