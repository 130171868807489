import React from "react"
import PropTypes from "prop-types"
import IMG from "gatsby-image"
import styles from "./featureDisplayContent.module.css"

const FeatureDisplayContent = ({ icon, title, description, className }) => {
  return (
    <div className={className}>
      <div className={styles.container}>
        <IMG className={styles.icon} fluid={icon} />
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  )
}

FeatureDisplayContent.prototypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
}

export default FeatureDisplayContent
